// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleDown      as fasFaAngleDown      } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleLeft      as fasFaAngleLeft      } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight     as fasFaAngleRight     } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faBaby           as fasFaBaby           } from "@fortawesome/pro-solid-svg-icons/faBaby";
import { faCalendarAlt    as fasFaCalendarAlt    } from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";
import { faCat            as fasFaCat            } from "@fortawesome/pro-solid-svg-icons/faCat";
import { faClock          as fasFaClock          } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment        as fasFaComment        } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faDog            as fasFaDog            } from "@fortawesome/pro-solid-svg-icons/faDog";
import { faEnvelope       as fasFaEnvelope       } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faFilePdf        as fasFaFilePdf        } from "@fortawesome/pro-solid-svg-icons/faFilePdf";
import { faFolder         as fasFaFolder         } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faGift           as fasFaGift           } from "@fortawesome/pro-solid-svg-icons/faGift";
import { faPawClaws       as fasFaPawClaws       } from "@fortawesome/pro-solid-svg-icons/faPawClaws";
import { faQuestionCircle as fasFaQuestionCircle } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faSearch         as fasFaSearch         } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faTag            as fasFaTag            } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faTimes          as fasFaTimes          } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faUserCircle     as fasFaUserCircle     } from "@fortawesome/pro-solid-svg-icons/faUserCircle";

import { faBan            as farFaBan            } from "@fortawesome/pro-regular-svg-icons/faBan";
import { faBars           as farFaBars           } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faCircle         as farFaCircle         } from "@fortawesome/pro-regular-svg-icons/faCircle";

import { faFacebook       as fabFaFacebook       } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faLinkedinIn     as fabFaLinkedinIn     } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faInstagram      as fabFaInstagram      } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitter        as fabFaTwitter        } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faXTwitter       as fabFaXTwitter       } from "@fortawesome/free-brands-svg-icons/faXTwitter";

/**
 * Add solid icons
 */
library.add(fasFaAngleDown, fasFaAngleLeft, fasFaAngleRight, fasFaBaby, fasFaCalendarAlt, fasFaCat, fasFaClock, fasFaComment, fasFaDog, fasFaEnvelope, fasFaFilePdf, fasFaFolder, fasFaGift, fasFaPawClaws, fasFaQuestionCircle, fasFaSearch, fasFaTag, fasFaTimes, fasFaUserCircle);

/**
 * Add regular icons
 */
library.add(farFaBan, farFaBars, farFaCircle);

/**
 * Add brand icons
 */
library.add(fabFaFacebook, fabFaInstagram, fabFaLinkedinIn, fabFaTwitter, fabFaXTwitter);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
